<template>
  <div id="LoginPrincipalContainer"  style="min-height:500px">
    
    <v-row class="ma-0 pa-0 centered" v-bind:style="{ 'background-color':COR_PRINCIPAL }">

      <!-- COL ESQUERDA ------------------------->
      <v-col v-if="!isMobile" class="container-background d-flex" justify="end">
          <!-- 
            <div class="logo-container">
            <img class="logo" src="../../assets/younect_vertical.png" />
          </div>

          <h1 class="title-wellcome">Bem vindo ao YouNect - Imob!</h1>

          <p class="text-wellcome">
            Acima de tudo, é fundamental ressaltar que o desenvolvimento
            contínuo de distintas formas de atuação é uma das consequências dos
            conhecimentos estratégicos para atingir a excelência.
          </p> -->
      </v-col> 
      <!-- FIM COL ESQUERDA ------------------------->

      <!-- COL DIREITA ------------------------->
      <v-col v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="col-login d-flex flex-column justify-center" >

        <form>
          <div v-if="store_Login.step === 'LoginEntrar'" style="min-height:400px" v-bind:style="{ 'background-color':COR_PRINCIPAL }">
            <transition :name="store_Login.currentTransition" mode="out-in" style="overflow-x: hidden;">
              <LoginEntrar ref="LoginEntrar"/>
            </transition>
          </div>

          <div v-if="store_Login.step === 'LoginRegistrar'" style="min-height:400px" v-bind:style="{ 'background-color':COR_PRINCIPAL }">
            <transition :name="store_Login.currentTransition" mode="out-in" style="overflow-x: hidden;">
              <LoginRegistrar ref="LoginRegistrar"/>
            </transition>
          </div>

          <div v-if="store_Login.step === 'LoginRegistrou'" style="min-height:400px" v-bind:style="{ 'background-color':COR_PRINCIPAL }">
            <transition :name="store_Login.currentTransition" mode="out-in" style="overflow-x: hidden;">
              <LoginRegistrou ref="LoginRegistrou"/>
            </transition>
          </div>

          <div v-if="store_Login.step === 'LoginRegistrouSemConfirmacao'" style="overflow-x: hidden; min-height:400px" v-bind:style="{ 'background-color':COR_SECUNDARIA }">
            <transition :name="store_Login.currentTransition" mode="out-in" style="overflow-x: hidden;">
              <LoginRegistrouSemConfirmacao ref="LoginRegistrouSemConfirmacao"/>
            </transition>
          </div>

          <div v-if="store_Login.step === 'LoginReenviarSenha'" style="overflow-x: hidden; min-height:400px" v-bind:style="{ 'background-color':COR_PRINCIPAL }">
            <transition :name="store_Login.currentTransition" mode="out-in" style="overflow-x: hidden;">
              <LoginReenviarSenha ref="LoginReenviarSenha"/>
            </transition>
          </div>

        </form>

      </v-col>  
       <!-- FIM COL DIREITA ------------------------->
    </v-row>


  </div>
</template>

<script>
import store_Login from "./store_Login";
import store_usuario from "../../store/store_usuario";
import { COR_PRINCIPAL, COR_SECUNDARIA } from "../../services/constantes";

import LoginEntrar from "./LoginEntrar.vue";
import LoginRegistrar from "./LoginRegistrar.vue";
import LoginRegistrou from "./LoginRegistrou.vue";
import LoginRegistrouSemConfirmacao from "./LoginRegistrouSemConfirmacao.vue";
//import LoginCabecalho from "./LoginCabecalho.vue";
import LoginReenviarSenha from './LoginReenviarSenha.vue';

export default {
  name: "LoginPrincipalContainer",

  components: {
    //LoginCabecalho,
    LoginEntrar,
    LoginRegistrar,
    LoginRegistrou,
    LoginRegistrouSemConfirmacao,
    LoginReenviarSenha,
  },
  
  data() {
    return {
      store_Login   : store_Login,
      store_usuario : store_usuario,

      COR_PRINCIPAL         : COR_PRINCIPAL,
      COR_SECUNDARIA        : COR_SECUNDARIA,

    }
  },
  
  methods: {
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },

  },

  mounted () {
    //console.log("this.$auth", this.$user);
  }


}

</script>

<style scoped>
*,
html {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  list-style: none;
  font-size: 16px !important;
  text-decoration: none !important;
  background: #e8e8e8;
}

.scroll {
  overflow: hidden !important;
}

/* Próximo Quadro ------------------- */
.next-leave-active {
  opacity: 0.7; }
.next-enter-active {
  transition: 0.2s; }
.next-enter {
  transform: translate(100%, 0); }
.next-leave-to {
  transform: translate(-100%, 0); }

/* Quadro Anterior ------------------ */
.prev-leave-active {
  opacity: 0.7; }
.prev-enter-active {
  transition: 0.2s; }
.prev-enter {
  transform: translate(-100%, 0); }
.prev-leave-to {
  transform: translate(100%, 0); }

.container-main {
  height: calc(100vh);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-blue-younect);
}

.col-wellcome {
  height: calc(100vh);
	margin: auto!important;
  background-color: transparent!important;
}

.col-login {
  height: calc(100vh);
	margin: auto!important;
  max-width: 550px;
}

.container-wellcome {
  margin: auto!important;
  background-color: transparent!important;
}

.logo-container {
	width: 200px;
	height: 180px;
  background-color: transparent!important;
}

.logo {
	max-width: 100%;
	height: auto;
	display: block;
  background-color: transparent!important;
}

.title-wellcome {
	margin: auto;
	font-weight: 400;
	font-size: 45px;
	font-family: "Open Sans", sans-serif;
	color: var(--COR_BACKGROUND_TEXT);
  background-color: transparent!important;
}

@media(max-width: 768px) {
  .title-wellcome {
    font-size: 30px;
    text-align: left;
  }
}

.text-wellcome {
	max-width: 600px;
  font-family: "Open Sans", sans-serif;
	color: var(--COR_BACKGROUND_TEXT);
  opacity: 0.8;
  background-color: transparent!important;
}

.title-login {
  font-weight: 400;
  color:#37383c;
  background-color: transparent!important;
}

.register-login {
  color: #37383c;
}

.register-create-login {
  color: var(--COR_PRINCIPAL);
}

.register-create-login:hover {
  cursor: pointer;
  text-decoration: underline!important;
  transition: 0.3s;
}

.btn {
  width: 100%;
  color: #FFF;
}

.container-background {
  background-image: url("../../assets/fundo-3.jpg")!important;
  background-repeat: no-repeat!important;
  background-position: top right!important;
  background-size: cover;
}

/* .centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */


</style>
